
<template>
   <div class="col-12 p-0 produto-linha" ref="produto" v-if="view == 'Linha'">
      <div class="card mb-2" :class="index % 2 == 0 ? '' : 'invert'">
         <div class="card-body p-3 pe-1 d-flex">
            <div class="icon" :class="page == 'PEDIDOS' ? 'd-none d-md-inline d-xl-none' : 'd-none d-xl-inline'">
               <img v-if="produto.foto != null" class="bg-white" :src="produto.foto" alt="Icone" @error="imageError">
               <img v-else-if="produto.fotos != null" class="bg-white" :src="produto.fotos.length == 0 ? '' : produto.fotos[0].foto" alt="Icone" @error="imageError">
               <img v-else class="bg-white" src="" alt="Icone" @error="imageError">
            </div>

            <div class="col">
               <div class="row m-0 font-12">
                  <div class="col-md-8 px-1 align-self-center mb-1 mb-md-0" v-if="page == 'PEDIDOS'">
                     <div class="row m-0">
                        <div class="col-12 p-0 text-center text-md-start mb-1">
                           <strong class="limitador font-15" :title="produto.nome">{{ produto.nome }}</strong>
                        </div>
                        <div class="col-6 col-lg-12 p-0 text-center text-md-start">
                           <div class="limitador">
                              <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Unitário:</strong> 
                              <small class="font-9">R$ </small>
                              {{ produto.preco == null ? 'Desconhecido' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-8 px-1 align-self-center mb-2 mb-md-0" v-if="page == 'PRODUTOS'">
                     <div class="row m-0">
                        <div class="col-12 p-0 text-center text-md-start mb-2">
                           <strong class="limitador font-15" :title="produto.nome">{{ produto.nome }}</strong>
                        </div>
                        <div class="col-6 pe-1 ps-0 d-none d-md-inline mb-2 mb-md-0">
                           <div class="limitador text-capitalize" :title="(produto.categoria == null ? 'Desconhecido' : produto.categoria) +' ('+  (produto.subcategoria == null ? 'Desconhecido' : produto.subcategoria) +')'">
                              <i class="far fa-file color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Categoria:</strong> 
                              {{ produto.categoria == null ? 'Desconhecido' : produto.categoria.toLowerCase() }} ({{ produto.subcategoria == null ? 'Desconhecido' : produto.subcategoria.toLowerCase() }})
                           </div>
                        </div>
                        <div class="col-6 p-0 text-center text-md-start mb-2 mb-md-0">
                           <div class="limitador">
                              <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Preço:</strong> 
                              R$ {{ produto.preco == null ? 'Desconhecido' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-14" v-if="page == 'PEDIDOS' && produto.semEstoque == false">
                     <i class="far fa-box color-theme font-10"></i><br>
                     {{ produto.quantidade == null ? 0 : parseFloat(produto.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}
                  </div>
                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-14" v-if="page == 'PRODUTOS'">
                     <div class="limitador">
                        <i class="far fa-box color-theme font-10 me-1"></i>
                        {{ produto.estoque == null ? 0 : parseFloat(produto.estoque).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}
                        <span class="font-11 text-secondary"> (Estoque)</span> 
                     </div>
                     
                     <div class="limitador">
                        <i class="far fa-shopping-cart color-theme font-10 me-1"></i>
                        {{ produto.quantidadeVendida == null ? 0 : parseFloat(produto.quantidadeVendida).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}
                        <span class="font-11 text-secondary">{{ parseFloat(produto.quantidadeVendida) > 0 ? ' (Vendidos)' : ' (Vendido)' }}</span>
                     </div>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-16" v-if="page == 'PEDIDOS' && produto.semEstoque == false">
                     <i class="far fa-box-open text-danger cursor-pointer" title="Sem estoque" @click="marcarSemEstoque(produto.id)"></i>
                  </div>
                  <div class="col-12 col-md-4 px-1 align-self-center text-center font-14" v-if="page == 'PEDIDOS' && produto.semEstoque == true">
                     <i class="far fa-box-open text-danger"></i><br>Sem estoque
                  </div>
                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-16" v-if="page == 'PRODUTOS'">
                     <i class="far fa-pen cursor-pointer me-5" title="Editar" @click="abrirEditarProduto"></i>
                     <i class="far fa-trash text-danger cursor-pointer" title="Excluir" @click="excluirProduto(produto.id)"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="card card-produto hover" ref="produto" v-else>
      <div class="text-center">
         <img v-if="produto.foto != null" class="bg-white" :src="produto.foto == null ? '' : produto.foto" alt="Icone" @error="imageError">
         <img v-else-if="produto.fotos != null" class="bg-white" :src="produto.fotos.length == 0 ? '' : produto.fotos[0].foto" alt="Icone" @error="imageError">
         <img v-else class="bg-white" src="" alt="Icone" @error="imageError">

         <div class="limitador font-15 my-1">
            <strong class="align-self-center mx-auto">{{ produto.nome.toUpperCase() }}</strong>
         </div>
         <div class="card-descricao limitador">
            <span class="text-secondary" title="Categoria (Subcategoria)">
               <i class="far fa-file font-10 color-theme me-1"></i> {{ produto.categoria == null ? 'Desconhecido' : produto.categoria.toLowerCase() }} ({{ produto.subcategoria == null ? 'Desconhecido' : produto.subcategoria.toLowerCase() }})
            </span>
         </div>
         <span class="badge badge2 bg-outline-theme cursor-pointer mb-2 mt-1 me-1" @click="abrirEditarProduto"> 
            <i class="far fa-pen font-10 me-1"></i> Editar
         </span>
         <span class="badge badge2 border-danger text-danger cursor-pointer mb-2 mt-1 ms-1" @click="excluirProduto(produto.id)"> 
            <i class="far fa-trash font-10 me-1"></i> Excluir
         </span>
         <div class="card-descricao mb-0">
            <span class="text-secondary font-13" title="Preço">
               <small class="font-10 color-theme">R$ </small>{{ produto.preco == null ? 'Desconhecido' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
            </span>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Produto',
   props: ['produto', 'index', 'view', 'page'],
   data: function() {
		return {
         urlAtual: window.location.hostname
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      abrirEditarProduto : function () {
         this.$emit('abrirEditarProduto', this.produto)
      },
      excluirProduto : function (id) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Excluir produto',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'adminproduct/deleteproduct',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername,
                     'Content-Type': 'application/json'
                  },
                  params:{
                     id: id
                  }
               }).then(function () {
                  ref.$refs.produto.style.display = 'none'

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Produto excluído!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      },
      marcarSemEstoque : function (id) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Marcar como sem estoque',
            text: 'Isso não poderá ser desfeito!',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'adminproduct/outofstock',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: id
                  }
               }).then(function (response) {
                  ref.$emit('attSemEstoque', response.data)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Marcação salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      }
   }
}

</script>