<template>
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 col-sm-6 col-xl-3 px-2 mb-3">
                     <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo de pesquisa</label>
                     <v-select :options="['Status', 'Forma de pagamento']" v-model="pesquisa.tipo" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-xl-4 px-2 mb-3"  v-if="pesquisa.tipo == 'Status'">
                     <label><i class="far fa-clock color-theme font-12 me-1"></i> Status</label>
                     <v-select :options="['Aguardando pagamento', 'Pendente entrega', 'Cancelado', 'Finalizado']" v-model="pesquisa.status" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-12 col-sm-6 col-xl-4 px-2 mb-3" v-if="pesquisa.tipo == 'Forma de pagamento'">
                     <label><i class="far fa-credit-card color-theme font-12 me-1"></i> Forma de pagamento</label>
                     <v-select :options="['PagHiper', 'MercadoPago', 'PayPal', 'In-game']" v-model="pesquisa.formaPgto" placeholder="Selecione uma opção" />
                  </div>
						<div class="col-6 col-sm-4 col-xl-2 px-1 mb-3">
                     <label><i class="far fa-calendar-check color-theme font-12 me-1"></i> Data Inicial</label>
                     <datePicker :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataInicio"></datePicker>
                  </div>
                  <div class="col-6 col-sm-4 col-xl-2 px-1 mb-3">
                     <label><i class="far fa-calendar-times color-theme font-12 me-1"></i> Data final</label>
                     <datePicker :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataFim"></datePicker>
                  </div>
                  <div class="col-12 col-sm-4 col-xl-1 px-2 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="buscarPedidos">
                        <i class="fas fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-12 text-end font-16 color-theme mb-2">
                     <i class="fal fa-th-large cursor-pointer" title="Quadrado" :class="pedidosView != 'Quadrado' ? 'text-secondary' : ''" @click="toggleView('Quadrado')"></i>
                     <i class="fal fa-th-list ms-3 cursor-pointer" title="Linha" :class="pedidosView != 'Linha' ? 'text-secondary' : ''" @click="toggleView('Linha')"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="resultado.length != 0">
         <div :class="pedidosView == 'Linha' ? 'row m-0' : 'cards'">
            <pedido v-for="(pedido, index) in resultado" :key="index" :pedido="pedido" :index="index" :view="pedidosView" @visualizar="visualizar($event)" />
         </div>
      </div>

      <div class="col-12 py-5 text-center" v-else>Nenhum pedido encontrado</div>

      <!-- modalVisualizar -->
      <div class="modal fade" id="modalVisualizar" tabindex="-1" aria-labelledby="modalVisualizarLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalVisualizarLabel">Pedido (Nº {{ pedido.venda.id }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 col-md-6 col-xl-4 px-1 mb-2">
                        <div class="card">
                           <div class="card-body" v-if="pedido.venda.status != undefined">
                              <div class="row m-0" v-if="pedido.venda.tipoEntrega == 'Entregar no meu domicílio'">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Informações do cliente</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-1" v-if="String(pedido.venda.nomeUsuario).length != 0 && pedido.venda.nomeUsuario != 'Desconhecido'">
                                    <strong class="color-theme me-1">Nome: </strong> {{ pedido.venda.nomeUsuario }}
                                 </div>
                              </div>
                              <div class="row m-0" v-else>
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Informações do cliente</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-1" v-if="pedido.venda.nomeUsuario != null && pedido.venda.nomeUsuario.length != 0">
                                    <strong class="color-theme me-1">Nome: </strong> {{ pedido.venda.nomeUsuario }}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-12 col-md-6 col-xl-4 px-1 mb-2">
                        <div class="card">
                           <div class="card-body" v-if="pedido.venda.status != undefined">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Informações do pedido</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-1" :class="String(pedido.venda.status).includes('Pendente') ? 'color-pendente' : String(pedido.venda.status).includes('Em separação') ? 'color-separacao' : String(pedido.venda.status).includes('Cancelado') ? 'color-cancelado' : String(pedido.venda.status).includes('Entrega em andamento') ? 'color-entrega' : String(pedido.venda.status).includes('Finalizado') ? 'color-finalizado' : ''">
                                    <strong class="color-theme me-1">Status: </strong> <strong>{{ pedido.venda.status }}</strong>
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Valor total: </strong> R$ {{ (parseFloat(pedido.venda.valorTotal) + parseFloat(pedido.venda.frete == null || isNaN(pedido.venda.frete) ? 0 : pedido.venda.frete)).toFixed(2) }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Forma de pagamento: </strong> {{ pedido.venda.formaPgto }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Pedido realizado: </strong>
                                    {{ new Date(pedido.venda.dataCompra.year +'/'+ pedido.venda.dataCompra.monthValue +'/'+ pedido.venda.dataCompra.dayOfMonth +' '+ pedido.venda.dataCompra.hour +':'+ pedido.venda.dataCompra.minute).toLocaleString() }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Última movimentação: </strong>
                                    {{ new Date(pedido.venda.dataUltMov.year +'/'+ pedido.venda.dataUltMov.monthValue +'/'+ pedido.venda.dataUltMov.dayOfMonth +' '+ pedido.venda.dataUltMov.hour +':'+ pedido.venda.dataUltMov.minute).toLocaleString() }}
                                 </div>
                                 <div class="col-12 px-1" v-if="pedido.venda.observacoes != null && pedido.venda.observacoes.length != 0">
                                    <strong class="color-theme me-1">Observações: </strong> {{ pedido.venda.observacoes }}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-12 col-xl-4 px-1 mb-2">
                        <div class="card">
                           <div class="card-body" v-if="pedido.venda.status != undefined">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Produtos</h6>
                                    <hr>
                                 </div>
                                 
                                 <produto v-for="(produto, index) in pedido.venda.produtos" :key="index" :produto="produto" :index="index" :view="'Linha'" :page="'PEDIDOS'" @attSemEstoque="attSemEstoque($event)" />

                                 <div class="col-12 px-1 py-5 text-center" v-if="pedido.venda.produtos.length == 0">
                                    Nenhum produto encontrado
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import pedido from '@/components/admin/pedidos/Pedido.vue'
import produto from '@/components/admin/produtos/Produto.vue'

export default {
	name: 'AdmPedidos',
   data: function () {
      return {
         pedidosView: localStorage.pedidosView == undefined ? 'Linha' : localStorage.pedidosView,
         pesquisa: {'tipo': 'Status', 'status': 'Aguardando pagamento', 'formaPgto': 'PagHiper', 'dataInicio': null, 'dataFim': null},
         pedido: {'venda': {}, 'usuario': {}},
         resultado: [],
			ptBR: ptBR
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      pedido, produto
   },
   methods: {
      buscarPedidos : function () {
         if (this.pesquisa.tipo == null || this.pesquisa.tipo == '') {
            this.pesquisa.tipo = 'Status'
         } 
         
         if ((this.pesquisa.tipo == 'Status' && this.pesquisa.status == null) || (this.pesquisa.tipo == 'Forma de pagamento' && this.pesquisa.formaPgto == null)) {
            
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma '+ this.pesquisa.tipo
            });

            return
         }

			let valor = '';

			switch (this.pesquisa.tipo) {
				case 'Status':
					valor = this.pesquisa.status
				break;
				case 'Forma de pagamento':
					valor = this.pesquisa.formaPgto
				break;
			}

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'adminproduct/searchorders',
				headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               tipo: this.pesquisa.tipo,
               valor: valor,
               dataInicio: this.pesquisa.dataInicio.getDate() +'-'+ (this.pesquisa.dataInicio.getMonth() + 1) +'-'+ this.pesquisa.dataInicio.getFullYear(),
               dataFim: this.pesquisa.dataFim.getDate() +'-'+ (this.pesquisa.dataFim.getMonth() + 1) +'-'+ this.pesquisa.dataFim.getFullYear()
            }
         }).then(response => {
            this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
            this.$store.dispatch('buscarPedidosPendentes')
         });
      },
      attSemEstoque : function (venda) {
         this.buscarPedidos()
         this.pedido.venda = venda
      },
      toggleView : function (view) {
         localStorage.pedidosView = view
         this.pedidosView = view
      },
      visualizar : function (pedido) {
         this.pedido.usuario = pedido.usuario
         this.pedido.venda = pedido.venda

         $('#modalVisualizar').modal('show')
      },
   },
   mounted() {
      this.pesquisa.dataFim = new Date()
      this.pesquisa.dataInicio = new Date(new Date().setDate(this.pesquisa.dataFim.getDate() - 7))
   }
}

</script>

<style scoped>

.fa-th-large, .fa-th-list {
   transform: translateY(-3px) scale(1.10);
}

.fa-th-large:hover, .fa-th-list:hover {
   transform: translateY(-3px) scale(1.20);
}

</style>